
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


* {
  box-sizing: border-box;
}

html,
body {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  
  text-size-adjust: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 1em;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

textarea {
  padding: 8px;
}


@media screen and (max-width: 620px) {
  .homepage p {
    /* -ms-zoom: 0.5; */
    zoom: 0.5;
  }
}


